import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, useNavigate, Routes, Navigate, useLocation } from 'react-router-dom';
import 'moment/locale/ru';
import queryString from 'query-string';

import 'src/styles/main.sass';
import 'src/styles/override.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { getCurrentUser, updateIsNewAccount } from 'src/store/actions/auth';
import LoginPage from 'src/pages/login';
import LandingPage from 'src/pages/landing';
import ProfilePage from 'src/pages/profile';
import RegisterPage from 'src/pages/register';
import ProjectPage from 'src/pages/project';
import AccreaditionPage from 'src/pages/accreadition';
import ForgotPasswordPage from 'src/pages/forgot-password';
import BuyTicketPage from 'src/pages/buy-ticket';
import OrderPage from 'src/pages/order';
import MyOrderPage from 'src/pages/my-order';
import PaymentFail from 'src/pages/payment-fail';
import PaymentSuccess from 'src/pages/payment-success';
import ArchivedOrderPage from 'src/pages/archived-orders';
import FAQsPage from 'src/pages/faqs';
import { LOCALIZATION } from 'src/utils/constant';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import VizitsPage from 'src/pages/vizits';
import VizitsSearchPage from 'src/pages/vizits-search';
import BusinessProgramPage from 'src/pages/business-program';
import { getCountriesList, joinProject } from 'src/store/actions/app';
import { setDefaultTimezone } from 'src/utils/time';
import MyTicketPage from 'src/pages/my-ticket';
import LoginExternalPage from 'src/pages/login-external';
import VerifyUserPage from 'src/pages/verify-user';
import PublicVizitPage from 'src/pages/public-vizit';
import VCardPage from 'src/pages/v-card';
import { routes } from 'src/utils/routes';
import ProjectChatPage from 'src/pages/chat';
import { projectsAPIs } from 'src/services';
import SelfRegistrationPage from 'src/pages/self-registration';
import { useCountries, useIndustries, useLanguage, useNotification } from 'src/hooks';

import CookieBanner from 'react-cookie-banner/lib';
import { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import ViewPDFModal from 'src/components/modal/ViewPDFModal';
import ProjectsPage from 'src/pages/projects';
import CalendarsPage from 'src/pages/project/components/CalendarPage';
import Loader from 'src/components/loader';
registerLocale('en', en);
registerLocale('ru', ru);

const appRoutes = [
  { ...routes.login, element: <LoginPage /> },
  { ...routes.loginExternal, element: <LoginExternalPage /> },
  { ...routes.forgotPassword, element: <ForgotPasswordPage /> },
  { ...routes.register, element: <RegisterPage /> },
  { ...routes.profile, element: <ProfilePage /> },
  // { ...routes.projectDetail, element: <ProjectPage /> },
  // { ...routes.projectAccreadition, element: <AccreaditionPage /> },
  // { ...routes.projectBuyTicket, element: <BuyTicketPage /> },
  // { ...routes.projectVizits, element: <VizitsPage /> },
  // { ...routes.projectBusinessProgram, element: <BusinessProgramPage /> },
  // { ...routes.myOrder, element: <MyOrderPage /> },
  // { ...routes.archivedOrders, element: <ArchivedOrderPage /> },
  { ...routes.orderDetail, element: <OrderPage /> },
  { ...routes.homepage, element: <LandingPage /> },
  { ...routes.paymentFail, element: <PaymentFail /> },
  { ...routes.paymentSuccess, element: <PaymentSuccess /> },
  { ...routes.faqs, element: <FAQsPage /> },
  { ...routes.myTicket, element: <MyTicketPage /> },
  // { ...routes.usersVerify, element: <VerifyUserPage /> },
  { ...routes.publicVizit, element: <PublicVizitPage /> },
  { ...routes.vCard, element: <VCardPage /> },
  { ...routes.projectChat, element: <ProjectChatPage /> },
  { ...routes.selfRegistration, element: <SelfRegistrationPage /> },
  { ...routes.calendars, element: <CalendarsPage /> },
];

function AppRoutes() {
  const [isInit, setInit] = React.useState(false);
  const currentUser = useSelector((state) => state.auth.user);
  const isNewAccount = useSelector((state) => state.auth.isNewAccount);
  const newProjectUuid = useSelector((state) => state.auth.newProjectUuid);

  const isLoggedIn = !!currentUser;
  const filteredRoutes = appRoutes.filter((r) => r.auth === isLoggedIn || r.isPublic);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectRoute = useMemo(() => {
    const redirectTo = location.pathname;
    const search = new URLSearchParams(location.search);
    const redirect = search.get('redirect');
    if (!isLoggedIn) {
      return `${routes.login.path}?redirect=${redirectTo}`;
    }
    return redirect || routes.homepage.path;
  }, [isLoggedIn, location]);

  // Custom hooks
  const { lang, localizeMessage, localizeText } = useLanguage();
  useNotification();
  useCountries();
  useIndustries();
  const handleRedirectToProjectDetail = async (currentUser, projectUuid, roleUuid) => {
    try {
      const res = await projectsAPIs.getProjects(`uuid=${projectUuid}`);
      const projectInfo = res.message?.[0];
      if (!currentUser.projectUuids?.includes(projectUuid)) {
        await joinProject(projectUuid, roleUuid);
      }
      setTimeout(() => {
        if (projectInfo?.modules?.accreditation) {
          navigate(routes.projectAccreadition.path.replace(':projectUuid', projectUuid));
        } else {
          navigate(routes.projectDetail.path.replace(':projectUuid', projectUuid));
        }
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!isInit || !currentUser) return;
    if (isNewAccount) {
      if (currentUser.projectUuids?.length > 0 && currentUser.accreditedBy !== '') {
        navigate(routes.projectAccreadition.path.replace(':projectUuid', newProjectUuid));
        updateIsNewAccount({ isNewAccount: false, newProjectUuid: '' });
        return;
      }
      if (currentUser.projectUuids?.length > 0 && !currentUser.accreditedBy) {
        handleRedirectToProjectDetail(currentUser, newProjectUuid);
        updateIsNewAccount({ isNewAccount: false, newProjectUuid: '' });
      }
    } else {
      if ([routes.login.path, routes.register.path].includes(location.pathname) && location.search) {
        const projectUuid = queryString.parse(location.search)?.projectUuid;
        const roleUuid = queryString.parse(location.search)?.roleUuid;
        if (projectUuid) {
          handleRedirectToProjectDetail(currentUser, projectUuid, roleUuid);
        }
      }
    }
  }, [currentUser, isNewAccount, newProjectUuid, isInit]);

  React.useEffect(() => {
    getCurrentUser().then(() => {
      setInit(true);
    });
    setDefaultTimezone();
  }, []);

  if (lang === LOCALIZATION.en_US) {
    moment.locale('en');
    momentTZ.defineLocale('en', moment.localeData()._config);
    momentTZ.locale('en');
  } else {
    moment.locale('ru');
    momentTZ.defineLocale('ru', moment.localeData()._config);
    momentTZ.locale('ru');
  }

  if (!isInit) {
    return (
      <div style={{ margin: '60px 0' }}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Routes>
        {filteredRoutes.map((r) => (
          <Route key={r.path} path={r.path} element={r.element} />
        ))}
        {isLoggedIn && (
          <Route path="/projects">
            <Route index={true} element={<ProjectsPage />} />
            <Route path=":projectUuid" element={<ProjectPage />} />
            <Route path=":detail/:projectUuid" element={<ProjectPage />} />
          </Route>
        )}

        <Route path="/health" element={() => 'Healthy'} />
        <Route path="*" element={<Navigate to={redirectRoute} />} />
      </Routes>
      <CookieBanner
        className="cookies-banner"
        dismissOnScroll={false}
        message={localizeMessage.THIS_WEBSITE_USES_COOKIES}
        buttonMessage={localizeText.ACCEPT}
        onAccept={() => {}}
        cookie="user-has-accepted-cookies"
      />
      <ViewPDFModal />
    </>
  );
}

export default AppRoutes;
