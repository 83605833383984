import { Button, CircularProgress } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { DashboardIcon2 } from '../../icons';
import { isSafari } from '../../utils/common';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import classes from './button-my-ticket.module.sass';
import { createNotification } from 'src/utils/notifications';
import { useDownloadFile, useLanguage } from 'src/hooks';
import { getMyTicket } from 'src/store/actions/app';

function ButtonMyTicket({
  userUuid,
  projectUuid,
  color = 'primary',
  className,
  useMuiColor,
  showIcon = true,
  ...restProps
}) {
  const { lang, localizeMessage } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { downloadFile } = useDownloadFile();

  const handleGotoMyTicket = async (e) => {
    if (!userUuid) return;
    e.stopPropagation();
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await getMyTicket(userUuid, projectUuid, true);
      downloadFile(response, 'Билет');
    } catch (error) {
      createNotification(localizeMessage.ERROR, 'error');
    } finally {
      setIsLoading(false);
    }
    // const url = routes.myTicket.path.replace(':userUuid', userUuid).replace(':projectUuid', projectUuid);
    // if (isSafari) {
    //   navigate(url);
    // } else {
    //   window.open(url, '_blank');
    // }
  };

  return (
    <Button
      style={{ fontSize: '14px', fontWeight: '700', padding: '12px 10px' }}
      className={cx(className, useMuiColor ? false : classes.root)}
      color={color}
      endIcon={<SouthIcon />}
      onClick={handleGotoMyTicket}
      disabled={isLoading}
      {...restProps}
    >
      {TEXT[lang].DOWNLOAD_TICKET}
    </Button>
  );
}

export default ButtonMyTicket;
