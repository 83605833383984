import { ChevronLeft } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MeetingsCalendar from 'src/components/meetings-calendar';
import { useLanguage } from 'src/hooks';
import { userAPIs } from '../../../services';
import classes from './calendar.module.sass';
import Loader from 'src/components/loader';

function CalendarVizitsPage({ projectUuid, setTab }) {
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [userMeetings, setUserMeetings] = useState([]);
  const { localizeMessage, localizeText } = useLanguage();

  const getAvatarMember = async (memberUuid) => {
    try {
      const rawAvatar = await userAPIs.getAvatarMemberVizits(memberUuid);
      return rawAvatar;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const fetchMeetings = async () => {
    try {
      const res = await userAPIs.getUserMeetings(projectUuid, 'approved');
      const rawMembers = res.message || [];
      let avatars = [];
      for (let i = 0; i < rawMembers.length; i++) {
        const rawAvatar = await getAvatarMember(rawMembers[i].avatar);
        avatars.push(rawAvatar);
      }
      setUserMeetings(rawMembers.map((m, i) => ({ ...m, avatar: avatars[i] })));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchMeetings();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <IconButton onClick={() => setTab(null)}>
          <ChevronLeft />
        </IconButton>
        <h1 className={classes.title}>{localizeText.CALENDAR}</h1>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {userMeetings?.length === 0 ? (
            <Typography sx={{ fontSize: 18, textAlign: 'center' }}>
              {localizeMessage.DO_NOT_HAVE_APPOINTMENTS}
            </Typography>
          ) : (
            <MeetingsCalendar meetings={userMeetings} userUuid={user?.uuid} />
          )}
        </>
      )}
    </div>
  );
}

export default CalendarVizitsPage;
