import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import BookingItemCard from './BookingItemCard';
import { projectsAPIs } from 'src/services';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import moment from 'moment';

const BookingCatalog = ({ searchStr, activeTab, typeUuid }) => {
  const { projectUuid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedDate, setSelectedDate] = useState(+searchParams.get('date') || 0);

  const { lang, localizeText } = useLanguage();

  const fetchCatalog = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await projectsAPIs.getBookingItems(projectUuid, {
      type_uuid: typeUuid,
    });
    const items = res.message || [];
    const result = {
      dates: items
        .sort((a, b) => moment(a.apply_date.date).diff(moment(b.apply_date.date)))
        .reduce(
          (acc, item) =>
            acc.some((el) => el.value === item.apply_date.date)
              ? acc
              : [
                  ...acc,
                  {
                    value: item.apply_date.date,
                    label: moment(item.apply_date.date).format('ddd DD MMM'),
                  },
                ],
          []
        ),
      items,
    };
    if (!result.dates[searchParams.get('date')]) setSelectedDate(0);
    return result;
  };

  const {
    isLoading: isFetching,
    data: catalogItems = { dates: [], items: [] },
    refetch,
  } = useQuery(['fetchCatalog', projectUuid], fetchCatalog, {
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [activeTab]);

  useEffect(() => {
    searchParams.set('date', selectedDate);
    setSearchParams(searchParams);
  }, [selectedDate]);

  const filteredData = useMemo(() => {
    return catalogItems.items.filter((item) => {
      const filterText = searchStr.trim().toLowerCase();
      const location = item.apply_location.localization[lang].address;
      const name = item.apply_entity.localization[lang].name;
      const locationName = item.apply_location.localization[lang].name;
      const itemString = [location, name, locationName].join(' ').toLowerCase();
      return (
        item.apply_date.date === catalogItems.dates[selectedDate].value &&
        (!filterText || filterText.split(' ').every((word) => itemString.includes(word))) &&
        (activeTab === 0 || item.apply_user.uuid)
      );
    });
  }, [searchStr, catalogItems, selectedDate, activeTab]);

  console.log('catalogItems', catalogItems, selectedDate);

  return (
    <Stack sx={{ width: '100%' }}>
      {isFetching ? (
        <Loader />
      ) : (
        <>
          {catalogItems.dates.length > 1 && (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              <Tabs
                centered
                value={selectedDate}
                onChange={(e, val) => {
                  setSelectedDate(val);
                }}
                sx={{
                  '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                  },
                }}
              >
                {catalogItems.dates.map((date, i) => {
                  let { label } = date;
                  const [s1, ...s2] = date.label.split(' ');
                  label = (
                    <span>
                      {s1} <br /> {s2.join(' ')}
                    </span>
                  );
                  return <Tab id={`booking-date-tab-${i}`} key={date.value} label={label} />;
                })}
              </Tabs>
            </Box>
          )}
          {filteredData.length ? (
            <Grid container spacing={3} justifyContent="center">
              {filteredData.map((item) => (
                <Grid item xs={12} md={6} xl={4} key={item.apply_relation.id}>
                  <BookingItemCard item={item} activeTab={activeTab} selectedDate={selectedDate} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography align="center" variant="body" mt={10}>
              {activeTab === 0 ? localizeText.BOOKING_EMPTY_CATALOG : localizeText.BOOKING_EMPTY_APPLIES}
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};

export default BookingCatalog;
