import { CalendarMonth } from '@mui/icons-material';
import { Badge, Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';
import moment from 'moment';
import { useRef } from 'react';
import Countdown from 'react-countdown';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useProjectHeight, useResponsive } from 'src/hooks';
import useProjectImage from 'src/hooks/useProjectImage';
import { routes } from 'src/utils/routes';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import ButtonMyTicket from '../button-my-ticket';
import JoinProjectButton from '../join-project-button';
import UserRole from '../user-role';
import UserStatus from '../user-status';
import classes from './project-card.module.sass';
import { color } from 'highcharts';

function ProjectCard({ project, fullWidth, showProjectDetail, className, help }) {
  const ref = useRef();
  const navigate = useNavigate();
  const isDesktop = useResponsive();

  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.app.roles);
  const order = useSelector((state) => state.app.order);
  const { mainPhoto } = useProjectImage({ project });

  const projectUuid = project.uuid;
  const isJoinedProject = user?.projectUuids?.includes(projectUuid);
  const role = roles.find((role) => role.id === user?.projectSettings?.[projectUuid]?.mainRoleId);
  const numOfPackages =
    order?.find((item) => item.projectUuid === projectUuid)?.packages?.reduce((p, c) => (p += c.count), 0) || 0;
  const price = project.price?.['643']?.priceFrom ?? 0;
  const isAccredited = user?.projectSettings?.[projectUuid]?.isAccredited;
  const readyToAccreditate = false;

  const gotoShoppingCartProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(routes.orderDetail.path.replace(':projectUuid', projectUuid));
  };

  const getRemainingNumDay = (installationDatePlan) => {
    const result = moment.unix(installationDatePlan).diff(moment(), 'd');
    if (result < 0) {
      return <span className={classes.text}>{TEXT[lang].ALREADY_STARTED}&nbsp;</span>;
      // return <Chip label={TEXT[lang].ALREADY_STARTED} color="success"></Chip>;
    }
    if (result < 1) {
      return (
        <>
          <span className={classes.text}>{TEXT[lang].UNTIL_START}&nbsp;</span>
          <span className={classes.date}>
            <Countdown date={installationDatePlan * 1000} renderer={rendererCountdown} />
          </span>
        </>
      );
    }
    return (
      <>
        <span className={classes.text}>{TEXT[lang].UNTIL_START}&nbsp;</span>
        <span className={classes.date}>{result}&nbsp;</span>
        <span className={classes.text}>{TEXT[lang].DAYS}&nbsp;</span>
      </>
    );
  };

  const handleGotoProject = () => {
    navigate(routes.projectDetail.path.replace(':projectUuid', projectUuid));
  };

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <span>
          {parseInt(hours).toString().padStart(2, '0')}:{parseInt(minutes).toString().padStart(2, '0')}:
          {parseInt(seconds).toString().padStart(2, '0')}
        </span>
      );
    }
  };
  const { height } = useProjectHeight({ ref });

  return (
    <Grid>
      {help && !isAccredited && (
        <Box sx={{ p: 3 }}>
          Для того чтобы заполнить анкету нажмите "Добавить в мой список", затем "Заполнить анкету"
        </Box>
      )}
      <Card
        className={classNames(classes.projectCard, className)}
        ref={ref}
        sx={{ mr: { xs: 0, md: fullWidth ? '0px' : '1rem' }, borderRadius: '0' }}
        onClick={handleGotoProject}
      >
        {mainPhoto ? (
          <LazyLoad>
            <Box className={classes.backgroundImage}>
              <img src={mainPhoto} width="100%" />
            </Box>
          </LazyLoad>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            className={classes.backgroundImage}
            sx={{ background: '#d5d5d5 !important', minHeight: '280px' }}
          >
            <CalendarMonth sx={{ fontSize: '100px', color: 'gray' }} />
          </Stack>
        )}
        <Stack className={classes.wrapper} justifyContent="space-between" gap="24px">
          <Grid container spacing={6} width="100%" height="100%" margin="0">
            <Grid item xs={1} position="relative">
              {isJoinedProject && (
                <Box className={classes.joinBanner}>
                  <Typography sx={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)', color: '#fff' }}>
                    Вы участник
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container xs={11} spacing={6} width="100%" height="100%" margin="0">
              <Grid item xs={12} sm={8} position="relative">
                <Box>
                  <JoinProjectButton
                    project={project}
                    redirectUrl={showProjectDetail ? routes.homepage.path : ''}
                    size={isDesktop ? 'medium' : 'small'}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                direction="column"
                alignItems="start"
                justifyContent="center"
                borderLeft="5px dotted rgb(255, 255, 255, 22%)"
                position="relative"
              >
                <Stack gap="12px" alignItems="start">
                  <UserRole
                    role={role}
                    sx={{ alignItems: 'center', display: 'flex', color: '#333', fontWeight: '500' }}
                    icon={<CheckCircleIcon />}
                  />
                  <UserStatus
                    isAccredited={isAccredited}
                    readyToAccreditate={readyToAccreditate}
                    sx={{
                      height: '30px',
                      alignItems: 'center',
                      display: 'flex',
                      marginBottom: '40px',
                      color: '#333',
                      fontWeight: '500',
                    }}
                    icon={<CheckCircleIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(routes.projectAccreadition.path.replace(':projectUuid', projectUuid));
                    }}
                    project={projectUuid}
                  />
                </Stack>
                {isAccredited && (
                  <Stack width="90%">
                    <ButtonMyTicket
                      userUuid={user.uuid}
                      projectUuid={projectUuid}
                      size={isDesktop ? 'medium' : 'small'}
                    />
                  </Stack>
                )}
                <Box className={classes.circleTop}></Box>
                <Box className={classes.circleBottom}></Box>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </Grid>
  );
}

export default ProjectCard;
