import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setIndustriesList } from 'src/store/actions/app';
import industries from '../configs/industries.json';

function useIndustries() {
  const savedIndustries = useSelector((state) => state.app.industries);
  useEffect(() => {
    if (savedIndustries?.length === 0 || savedIndustries?.length !== industries?.length) {
      setIndustriesList(industries);
    }
  }, [savedIndustries]);
}

export default useIndustries;
