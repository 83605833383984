import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { v4 as uuidv4 } from 'uuid';
import { setPayingOrders } from '../../store/actions/app';
import { createMyOrder } from '../../store/actions/auth';
import { getTransMessages } from '../../utils/common';
import { EVENTS, LOCALIZATION, PAYMENT_METHODS, TEXT, WEBSITE_BASE_URL } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { Button } from '../button';
import Loader from '../loader';
import classes from './check-out-form.module.sass';
import { useQuery } from 'react-query';
import { userAPIs } from 'src/services';

function CheckOutForm({ isLoading, cart, projectUuid }) {
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.bankCard.value);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const payingOrders = useSelector((state) => state.app.payingOrders);
  const company = useSelector((state) => state.auth.company);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const fetchOffer = async ({ queryKey }) => {
    var langCode = 'en';
    if (lang === LOCALIZATION.ru_RU) {
      langCode = 'ru';
    }

    const [, projectUuid] = queryKey;
    const response = await userAPIs.getOfferFile({
      projectUuid,
      lang: langCode,
    });
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);
    return imageUrl;
  };

  const { isOffersLoading, data: offer } = useQuery(['fetchOffer', projectUuid], fetchOffer, {
    enabled: !!projectUuid,
    staleTime: 0,
  });

  console.log('oferrs', offer);
  const handleCreateOrder = async () => {
    const transMessages = getTransMessages();
    var langCode = 'EN';
    if (lang === LOCALIZATION.ru_RU) {
      langCode = 'RU';
    }
    try {
      const uuidOrder = uuidv4();
      const body = {
        orderType: paymentMethod,
        projectUuid,
        langCode: langCode,
        currency: '643',
        backUrlSucess: `${WEBSITE_BASE_URL}/payment/success?order=${uuidOrder}&projectUuid=${projectUuid}`,
        backUrlFail: `${WEBSITE_BASE_URL}/payment/fail?projectUuid=${projectUuid}`,
        order: [
          {
            basket: cart.map((item) => ({ amount: item.count, type: 'package', id: item.package.id })),
          },
        ],
      };
      setIsSubmitting(true);
      let response;
      switch (paymentMethod) {
        case PAYMENT_METHODS.bankCard.value:
          response = await createMyOrder(body);
          break;
        default:
          response = await createMyOrder(body);
          break;
      }
      if (response) {
        switch (paymentMethod) {
          case PAYMENT_METHODS.bankCard.value:
            createNotification(transMessages.PAYMENT_PROCESS_SUCCESSFUL);
            break;
          default:
            createNotification(transMessages.PAYMENT_INVOICE_PROCESS_SUCCESSFUL);
            break;
        }

        setPayingOrders((payingOrders || []).concat([{ uuid: uuidOrder, projectUuid, cart }]));
        if (response.url) {
          window.open(response.url, '_self');
        } else {
          navigate(routes.paymentSuccess.path + `?order=${uuidOrder}&projectUuid=${projectUuid}`);
        }
      } else {
        createErrorNotification(transMessages.PAYMENT_PROCESS_FAIL);
      }
    } catch (error) {
      createErrorNotification(transMessages.PAYMENT_PROCESS_FAIL);
    } finally {
      setIsSubmitting(false);
    }
  };

  const total = cart.reduce((p, c) => (p += c.package.price['643']?.price * c.count || 0), 0);
  return (
    <Stack justifyContent="center" alignItems="center">
      {isLoading || isOffersLoading ? (
        <Loader />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            marginBottom="18px"
            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}
          >
            <div>{TEXT[lang].TOTAL}</div>
            <div>
              <NumberFormat
                value={total}
                suffix=" руб"
                decimalScale={2}
                displayType="text"
                thousandSeparator=" "
                decimalSeparator=","
              />
            </div>
          </Stack>
          {cart.map((p) => {
            const name = p.package.localization?.[lang]?.name;
            return (
              <Stack
                key={p.id}
                direction="row"
                justifyContent="space-between"
                width="100%"
                marginBottom="12px"
                sx={{ fontSize: '17px', lineHeight: '28px', color: '#8A8A8E' }}
              >
                <div>
                  {name && !name.includes('null') ? name : p.package.localization?.[LOCALIZATION.ru_RU]?.name}
                  {p.count > 1 && ` (${p.count})`}
                </div>
                <div>
                  <NumberFormat
                    value={p.package.price['643']?.price * p.count}
                    suffix=" руб"
                    decimalScale={2}
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                  />
                </div>
              </Stack>
            );
          })}
        </>
      )}

      <FormControl sx={{ alignSelf: 'flex-start', margin: '32px 0' }}>
        <RadioGroup
          name="payment-method-radio"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          {Object.keys(PAYMENT_METHODS).map((key) => (
            <>
              <FormControlLabel
                key={key}
                sx={{ gap: '10px' }}
                value={PAYMENT_METHODS[key].value}
                control={<Radio />}
                label={PAYMENT_METHODS[key].label?.[lang]}
              />
              {key === 'bankAccount' && !company && (
                <FormHelperText>
                  <span>{TEXT[lang].NOT_HAVE_COMPANY}</span>
                </FormHelperText>
              )}
            </>
          ))}
        </RadioGroup>
      </FormControl>

      <Box sx={{ color: (theme) => theme.palette.primary.main, fontSize: '20px', lineHeight: '52px' }}>
        {TEXT[lang].TOTAL_AMOUNT + ' '}
        {
          <NumberFormat
            value={total}
            suffix=" руб"
            decimalScale={2}
            displayType="text"
            thousandSeparator=" "
            decimalSeparator=","
          />
        }
      </Box>

      <Button
        sx={{ height: 48, width: '80%' }}
        startIcon={
          isSubmitting && (
            <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#00000042' }} />
          )
        }
        disabled={cart.length === 0 || isSubmitting || !agreeTerms}
        onClick={handleCreateOrder}
      >
        {TEXT[lang].PAY}
      </Button>
      {!company && (
        // <Link sx={{ marginTop: '24px ', height: 48, width: '80%', textAlign: 'center' }} href="/profile">
        //   {TEXT[lang].ADD_COMPANY}
        // </Link>
        <Button
          sx={{ marginTop: '24px', borderRadius: '4px !important', padding: '8px 24px !important' }}
          onClick={() => eventBus.emit(EVENTS.OPEN_COMPANY_MODAL)}
        >
          {TEXT[lang].ADD_COMPANY}
        </Button>
      )}
      <Stack direction="row" spacing={0.5} marginTop="16px" sx={{ width: '90%', alignItems: 'center' }}>
        <Checkbox
          onChange={() => setAgreeTerms(!agreeTerms)}
          checked={agreeTerms}
          sx={{ height: 'fit-content', fontSize: 17 }}
        />
        <Link download={TEXT[lang].OFFER + '.pdf'} href={offer}>
          {TEXT[lang].AGREE_WITH_TERMS} {TEXT[lang].TERMS_OF_USE_OF_THE_TRADING_PLATFORM}
        </Link>
      </Stack>
    </Stack>
  );
}

export default CheckOutForm;
