import React, { useState } from 'react';
import UserComponent from './users';
import { Email, KeyboardArrowLeft, KeyboardArrowRight, Person, Phone } from '@mui/icons-material';
import { Avatar, Button, Card, Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import classes from '../vizits-search.module.sass';
import { useSelector } from 'react-redux';
import { TEXT, LOCALIZATION } from '../../../utils/constant';
import { userChatAPIs } from 'src/services';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { createErrorNotification } from 'src/utils/notifications';

function changeQuotes(text) {
  var el = document.createElement('DIV');
  el.innerHTML = text;
  for (var i = 0, l = el.childNodes.length; i < l; i++) {
    if (el.childNodes[i].hasChildNodes() && el.childNodes.length > 1) {
      el.childNodes[i].innerHTML = changeQuotes(el.childNodes[i].innerHTML);
    } else {
      el.childNodes[i].textContent = el.childNodes[i].textContent
        .replace(/\x27/g, '\x22')
        .replace(/(\w)\x22(\w)/g, '$1\x27$2')
        .replace(/(^)\x22(\s)/g, '$1»$2')
        .replace(/(^|\s|\()"/g, '$1«')
        .replace(/"(\;|\!|\?|\:|\.|\,|$|\)|\s)/g, '»$1');
    }
  }
  return el.innerHTML;
}

function CompanySection({ data, projectUuid }) {
  const navigate = useNavigate();
  const members = data?.members || [];
  const pageCounter = Math.ceil(members.length / 4);
  const [currentPage, setCurrentPage] = useState(1);
  const [isExpand, setIsExpand] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const userMemberVizits = useSelector((state) => state.chat.userMemberVizits);
  const projectSettings = useSelector((state) => state.app.projectSettings);
  const typeProcessBDK = projectSettings.typeProcessBDK;

  const moreThanFourMembers = members.length > 4;
  const isMyCard = data?.ownerUuid === user?.uuid;
  const member = members[0] || {};

  const handleStartChat = async () => {
    try {
      const res = await userChatAPIs.createChat({
        firstMemberUuid: userMemberVizits?.[0]?.uuid,
        secondMemberUuid: members?.[0]?.uuid,
        projectUuid: projectUuid,
      });
      const newChat = res.message;
      navigate(routes.projectChat.path.replace(':projectUuid', projectUuid) + `?chatUuid=${newChat.uuid}`);
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  return (
    <Card className={classes.company}>
      <Grid container spacing={1} justifyContent="center">
        {isMyCard && (
          <Grid item xs={12} align="right">
            <Chip color="primary" label={TEXT[lang].THIS_IS_YOUR_VIZIT} />
          </Grid>
        )}

        <Grid item xs={12} align="center">
          {data.logo ? (
            <img src={data.logo} alt="logo" className={classes.logo} />
          ) : (
            <Avatar className={classes.avatar}>{(data?.name?.[0] || '') + (data?.name?.[1] || '')}</Avatar>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              // whiteSpace: 'nowrap',
              // textOverflow: 'ellipsis',
              // overflow: 'hidden',
              hyphens: 'auto',
              // textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {changeQuotes(data.name)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="#8A8A8E" className={classes.desc} sx={{ hyphens: 'auto', fontWeight: 'bold' }}>
            {data.description}
          </Typography>
        </Grid>

        {moreThanFourMembers && (
          <Grid item xs={12} algin="center">
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="8px">
              <IconButton onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                <KeyboardArrowLeft />
              </IconButton>
              <Typography>{`${currentPage} из ${pageCounter}`}</Typography>
              <IconButton onClick={() => setCurrentPage(Math.min(currentPage + 1, pageCounter))}>
                <KeyboardArrowRight />
              </IconButton>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack alignItems="center" marginY="8px" sx={{ hyphens: 'auto' }}>
            <Stack direction="row" gap="4px" alignItems="center">
              <Person sx={{ color: '#8A8A8E', fontSize: '16px' }} />
              <Typography color="#8A8A8E" className={classes.desc}>
                {member.fullName}
              </Typography>
            </Stack>
            <Stack direction="row" gap="4px" alignItems="center">
              <Email sx={{ color: '#8A8A8E', fontSize: '16px' }} />
              <Typography color="#8A8A8E" className={classes.desc}>
                {member.email}
              </Typography>
            </Stack>
            <Stack direction="row" gap="4px" alignItems="center">
              <Phone sx={{ color: '#8A8A8E', fontSize: '16px' }} />
              <Typography color="#8A8A8E" className={classes.desc}>
                {member.telephone}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        {!isMyCard && typeProcessBDK !== 'bdk' && (
          <Grid item xs={12}>
            <Button variant="contained" size="small" sx={{ fontSize: '1rem' }} onClick={handleStartChat}>
              {TEXT[lang].START_CHAT}
            </Button>
          </Grid>
        )}
      </Grid>

      {members && members.length > 0 && (
        <Grid container spacing={2} marginTop="8px">
          {members.slice((currentPage - 1) * 4, currentPage * 4).map((user, i) => (
            <Grid key={i} item xs={12}>
              <UserComponent projectUuid={data.projectUuid} user={user} isExpand={isExpand} />
            </Grid>
          ))}
        </Grid>
      )}
      {/* <div className={classes.expandBtn} onClick={() => setIsExpand(!isExpand)}>
        {isExpand ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}
      </div> */}
    </Card>
  );
}

export default CompanySection;
