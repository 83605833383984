import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Close, Email, Phone, Work } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
} from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AvatarUser } from '../../../components/avatar-user';
import { userAPIs } from '../../../services';
import { getDocumentOfMember } from '../../../store/actions/app';
import { DEFAULT_UTC_OFFSET, DOCUMENT_TYPES, LOCALIZATION, MESSAGES, TEXT } from '../../../utils/constant';
import { membersVizitsValidationSchema } from '../../../utils/formValidator';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { getTableOptions, getTimeSlotOptions } from './business-card';
import classes from './members.module.sass';
import { timeZoneConverter, timeZone, DEFAULT_TIMEZONE } from '../../../utils/time';
import ReactDatePicker from 'react-datepicker';

function Members({ projectUuid, member, membersVizits, setMembersVizits, setShowAdd, readOnly, fullRow }) {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [requireLogo, setRequireLogo] = useState(false);
  const [isReadyTalk, setIsReadyTalk] = useState(member ? member.isReadyTalk : true);
  const [slots, setSlots] = useState(member?.slots || []);
  const [timeSlot, setTimeSlot] = useState({
    tableNumber: '',
    date: moment().format('YYYY-MM-DD'),
    slot: '',
    fromTime: '',
    toTime: '',
    duration: 0,
  });
  const [isAdding, setIsAdding] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [addSlotsCustom, setAddSlotsCustom] = useState(false);
  const projectSettings = useSelector((state) => state.app.projectSettings);
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        fullName: member?.fullName,
        isReadyTalk: member?.isReadyTalk,
        telephone: member?.telephone,
        email: member?.email,
        role: member?.role,
        description: member?.description,
        slots: '',
        tableNumber: '',
      }),
      [member]
    ),
    resolver: yupResolver(membersVizitsValidationSchema(lang)),
  });

  const handleChange = async (file) => {
    if (isUploading) return;
    try {
      setIsUploading(true);
      setRequireLogo(false);
      if (!member) {
        setUploadedLogo(file);
      } else {
        const formData = new FormData();
        formData.append('fileDocument', file, file.name);
        const response = await userAPIs.uploadPhotoMembersVizitsProject(projectUuid, member.uuid, formData);
        setMembersVizits(membersVizits.map((m) => (m.uuid === member.uuid ? response.message : m)));
        createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const onSubmit = async (values) => {
    if (!member && !member?.avatar && !uploadedLogo) return setRequireLogo(true);
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const body = { ...values, isReadyTalk: Boolean(isReadyTalk), slots };
      const formData = new FormData();
      if (!member) {
        formData.append('fileDocument', uploadedLogo, uploadedLogo?.name);
        formData.append('memberVizit', JSON.stringify(body));
      }
      const res = member
        ? await userAPIs.patchMembersVizitsProject(projectUuid, member.uuid, body)
        : await userAPIs.createMembersVizitsProject(projectUuid, formData);

      if (member) {
        setMembersVizits(membersVizits.map((m) => (m.uuid === member.uuid ? res.message : m)));
        setIsEdit(false);
      } else {
        setMembersVizits([res.message].concat(membersVizits));
        setShowAdd(false);
      }

      createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      setIsChanged(false);
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    if (member) {
      setSlots(member?.slots || []);
      setIsEdit(false);
    } else {
      setShowAdd(false);
    }
    reset();
  };

  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      setIsChanged(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const isAddedSlot = (slots, newSlot) => {
    return slots.find(
      (slot) =>
        (newSlot.startTime > slot.startTime && newSlot.startTime < slot.endTime) ||
        (newSlot.endTime > slot.startTime && newSlot.endTime < slot.endTime) ||
        (newSlot.startTime < slot.startTime && newSlot.endTime > slot.endTime) ||
        (newSlot.startTime === slot.startTime && newSlot.endTime === slot.endTime)
    );
  };

  const handleAddSlot = () => {
    setIsAdding(true);
    const { tableNumber, date, slot, fromTime, toTime, duration } = timeSlot;
    if (addSlotsCustom) {
      let newSlots = [];
      let fromTimeMoment = moment(fromTime);
      do {
        const nextFromTime = fromTimeMoment.clone().add(duration, 'minute');
        const start = moment(date).format('DD-MM-YYYY ') + fromTimeMoment.format('HH:mm');
        const end = moment(date).format('DD-MM-YYYY ') + nextFromTime.format('HH:mm');
        const _start = moment(start, 'DD-MM-YYYY HH:mm').unix() * 1000;
        const _end = moment(end, 'DD-MM-YYYY HH:mm').unix() * 1000;
        const startTime = timeZoneConverter(moment(_start), DEFAULT_UTC_OFFSET, timeZone(moment(_start).toDate()));
        const endTime = timeZoneConverter(moment(_end), DEFAULT_UTC_OFFSET, timeZone(moment(_end).toDate()));
        const newSlot = {
          tableNumber: tableNumber || 0,
          startTime: startTime.unix() * 1000,
          endTime: endTime.unix() * 1000,
        };
        if (!isAddedSlot(slots, newSlot)) {
          newSlots.push(newSlot);
        }
        fromTimeMoment = nextFromTime;
      } while (fromTimeMoment.isBefore(moment(toTime), 'minute'));
      setSlots(slots.concat(newSlots).sort((a, b) => a.startTime - b.startTime));
    } else {
      const start = moment(date).format('DD-MM-YYYY ') + slot?.split(' - ')?.[0];
      const end = moment(date).format('DD-MM-YYYY ') + slot?.split(' - ')?.[1];
      const _start = moment(start, 'DD-MM-YYYY HH:mm').unix() * 1000;
      const _end = moment(end, 'DD-MM-YYYY HH:mm').unix() * 1000;
      const startTime = timeZoneConverter(moment(_start), DEFAULT_UTC_OFFSET, timeZone(moment(_start).toDate()));
      const endTime = timeZoneConverter(moment(_end), DEFAULT_UTC_OFFSET, timeZone(moment(_end).toDate()));
      const newSlot = {
        tableNumber: tableNumber || 0,
        startTime: startTime.unix() * 1000,
        endTime: endTime.unix() * 1000,
      };
      if (isAddedSlot(slots, newSlot)) {
        createErrorNotification(MESSAGES[lang].SLOT_BUSY_ERROR);
      } else {
        setSlots(slots.concat([newSlot]).sort((a, b) => a.startTime - b.startTime));
      }
    }
    setTimeSlot({
      date: moment().format('YYYY-MM-DD'),
      slot: '',
      tableNumber: '',
      fromTime: '',
      toTime: '',
      duration: 0,
    });
    setTimeout(() => setIsAdding(false), 200);
  };

  const labelStyle = { fontSize: 17, fontFamily: 'Lato', color: '#8A8A8E' };
  const inputStyle = { fontSize: 17, fontFamily: 'Lato', color: '#000' };
  const isEditing = !member || isEdit;
  const isLocked = member ? !isEdit : false;
  const ableAddSlot =
    timeSlot &&
    // (!showTableNumber || timeSlot.tableNumber) &&
    timeSlot.date &&
    (timeSlot.slot ||
      (timeSlot.fromTime &&
        timeSlot.toTime &&
        moment(timeSlot.fromTime).isBefore(moment(timeSlot.toTime)) &&
        timeSlot.duration));

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const resAvatar = await getDocumentOfMember(member.userUuid, DOCUMENT_TYPES.AVATAR, projectUuid);
        setAvatar(resAvatar);
        setUploadedLogo(resAvatar);
        // setMembersVizits(membersVizits.map((m) => (m.uuid === member.uuid ? { ...m, avatarFile: avatar } : m)));
      } catch (e) {
        console.log(e);
      }
    };

    if (member) fetchAvatar();
  }, [member]);

  const formatMemberToUser = (member) => {
    return {
      ...member,
      uuid: member.userUuid,
      avatarFile: avatar,
      firstName: member.fullName.split(' ')?.[0] || '',
      lastName: member.fullName.split(' ')?.[1] || '',
    };
  };

  return (
    <Grid item xs={12} md={fullRow ? 12 : 6}>
      <Grid>
        <Box sx={{ mx: 'auto', p: 3 }}>
          Если вы не хотите, чтобы ваша визитная карточка отображалась в каталоге участников Биржи деловых контактов,
          уберите галочку с опции «Готов к общению».
        </Box>
      </Grid>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Stack className={classes.card}>
            {member && !isEdit && !readOnly && (
              <IconButton color="primary" className={classes.editBtn} onClick={() => setIsEdit(true)}>
                <ModeEditIcon />
              </IconButton>
            )}
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              spacing={2}
              sx={{ marginBottom: '16px' }}
            >
              <AvatarUser user={formatMemberToUser(member)} hideUploadAvatar={!isEditing} setAvatar={setAvatar} />
              {/* <Stack style={{ position: 'relative' }}>
                <input
                  // ref={inputFileRef}
                  accept="image/jpg, image/jpeg, image/png"
                  className={classes.inputAvatar}
                  id="photo-member"
                  multiple={false}
                  type="file"
                  onChange={(e) => handleChange(e.target?.files?.[0])}
                />
                <label htmlFor="photo-member">
                  <Avatar
                    className={classes.avatar}
                    src={
                      member?.avatar
                        ? member?.avatar + `?dummy=${new Date().getTime()}`
                        : uploadedLogo
                        ? URL.createObjectURL(uploadedLogo)
                        : ''
                    }
                  />
                </label>
              </Stack> */}

              {member && !isEdit && (
                <div>
                  <div className={classes.name}>{member.fullName}</div>
                  <FormControlLabel
                    control={<Checkbox sx={{ height: 'fit-content', fontSize: 17 }} checked={isReadyTalk} />}
                    label="Готов к общению"
                  />
                </div>
              )}
              {requireLogo && !uploadedLogo && !member && (
                <p style={{ color: 'red' }}>* {MESSAGES[lang].PLEASE_UPLOAD_AVATAR}</p>
              )}
            </Stack>

            <Grid container spacing={fullRow ? 2 : 3}>
              {(isEdit || !member) && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ height: 'fit-content', fontSize: 17 }}
                        checked={isReadyTalk}
                        onClick={(e) => setIsReadyTalk(e.target.checked)}
                      />
                    }
                    label="Готов к общению"
                  />
                  <TextField
                    margin="normal"
                    label={TEXT[lang].FULLNAME_VIZIT}
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        '& .MuiInputLabel-asterisk': {
                          color: 'red',
                        },
                      },
                      style: labelStyle,
                    }}
                    InputProps={{
                      style: inputStyle,
                    }}
                    variant="standard"
                    defaultValue={getValues('fullName')}
                    {...register('fullName')}
                    error={errors.fullName ? true : false}
                    helperText={errors.fullName?.message ? errors.fullName.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
                    disabled={isLocked}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={fullRow ? 12 : 6}>
                {fullRow ? (
                  <TextField
                    {...register('telephone')}
                    value={getValues('telephone')}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone />
                        </InputAdornment>
                      ),
                      sx: {
                        '& .Mui-disabled': {
                          fill: '#000 !important',
                          '-webkit-text-fill-color': '#000 !important',
                        },
                      },
                    }}
                    disabled={isLocked}
                    helperText={errors.fullName?.message ? errors.fullName.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
                  />
                ) : (
                  <MuiPhoneNumber
                    label={TEXT[lang].NUMBER_TELEPHONE}
                    InputLabelProps={{
                      shrink: true,
                      style: labelStyle,
                    }}
                    InputProps={{
                      style: inputStyle,
                      sx: {
                        '& .Mui-disabled': {
                          fill: '#000 !important',
                          '-webkit-text-fill-color': '#000 !important',
                        },
                      },
                    }}
                    margin="normal"
                    style={{ width: '100%' }}
                    name="telephone"
                    variant="standard"
                    autoFormat={false}
                    defaultCountry={'ru'}
                    {...register('telephone')}
                    value={getValues('telephone')}
                    onChange={(value) => setValue('telephone', value)}
                    error={errors.telephone ? true : false}
                    helperText={errors.telephone?.message ? errors.fullName.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
                    disabled={isLocked}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={fullRow ? 12 : 6}>
                <Tooltip title={getValues('email')}>
                  {fullRow ? (
                    <TextField
                      {...register('email')}
                      value={getValues('email')}
                      variant="standard"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        ),
                        sx: {
                          '& .Mui-disabled': {
                            fill: '#000 !important',
                            '-webkit-text-fill-color': '#000 !important',
                          },
                        },
                      }}
                      disabled={isLocked}
                      helperText={errors.email?.message ? errors.fullName.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
                    />
                  ) : (
                    <TextField
                      type="email"
                      margin="normal"
                      label={TEXT[lang].EMAIL}
                      sx={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          '& .MuiInputLabel-asterisk': {
                            color: 'red',
                          },
                        },
                        style: labelStyle,
                      }}
                      InputProps={{
                        style: inputStyle,
                        sx: {
                          '& .Mui-disabled': {
                            fill: '#000 !important',
                            '-webkit-text-fill-color': '#000 !important',
                          },
                        },
                      }}
                      variant="standard"
                      defaultValue={getValues('email')}
                      {...register('email')}
                      error={errors.email ? true : false}
                      helperText={errors.email?.message ? errors.fullName.message : TEXT[lang].VISIT_PUBLICITY_HELPER}
                      disabled={isLocked}
                    />
                  )}
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                {fullRow ? (
                  <TextField
                    {...register('role')}
                    value={getValues('role')}
                    variant="standard"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Work />
                        </InputAdornment>
                      ),
                      sx: {
                        '& .Mui-disabled': {
                          fill: '#000 !important',
                          '-webkit-text-fill-color': '#000 !important',
                        },
                      },
                    }}
                    disabled={isLocked}
                  />
                ) : (
                  <TextField
                    label={fullRow ? '' : TEXT[lang].JOB_TITLE}
                    sx={{ width: '100%' }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        '& .MuiInputLabel-asterisk': {
                          color: 'red',
                        },
                      },
                      style: labelStyle,
                    }}
                    InputProps={{
                      style: inputStyle,
                      sx: {
                        '& .Mui-disabled': {
                          fill: '#000 !important',
                          '-webkit-text-fill-color': '#000 !important',
                        },
                      },
                    }}
                    variant="standard"
                    defaultValue={getValues('role')}
                    {...register('role')}
                    error={errors.role ? true : false}
                    helperText={errors.role?.message}
                    disabled={isLocked}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Tooltip
                  title={
                    'Опишите максимально подробно, в каких направлениях/партнерах/поставщиках/закупщиках вы заинтересованы на встречах – это поможет другим пользователям максимально быстро найти данную информацию через фильтры поиска системы и сделать Ваши встречи максимально целевыми и эффективными. Например: «Заинтересованы в новых партнерах в сфере закупок и поставок оптоволоконных технологий'
                  }
                >
                  <TextField
                    multiline={true}
                    label=""
                    minRows={6}
                    margin="normal"
                    style={{ color: '#000', ...inputStyle }}
                    fullWidth
                    placeholder={TEXT[lang].PLEASE_TELL_ABOUT_YOURSELF}
                    defaultValue={getValues('description')}
                    {...register('description')}
                    helperText={
                      errors.description?.message ? errors.fullName.message : TEXT[lang].VISIT_PUBLICITY_HELPER
                    }
                    disabled={isLocked}
                  />
                </Tooltip>
              </Grid>
              {slots?.length > 0 && (
                <Grid item xs={12}>
                  <Stack direction="row" flexWrap="wrap" justifyContent={{ xs: 'center', md: 'flex-start' }} gap="6px">
                    {slots.map((slot, i) => (
                      <>
                        {' '}
                        {!slot.isBusy && (
                          <div key={i} className={classes.slot}>
                            {`${momentTZ.tz(slot.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${momentTZ
                              .tz(slot.endTime, DEFAULT_TIMEZONE)
                              .format('HH:mm')}`}
                            {isEditing && (
                              <Close
                                sx={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}
                                onClick={() => {
                                  setSlots((old) => old.filter((_, iOld) => iOld !== i));
                                }}
                              />
                            )}
                          </div>
                        )}
                        {slot.isBusy && (
                          <Tooltip title="На данное время у вас уже есть подтвержденная встреча">
                            <div key={i} className={classes.slot}>
                              {`${momentTZ.tz(slot.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${momentTZ
                                .tz(slot.endTime, DEFAULT_TIMEZONE)
                                .format('HH:mm')}`}
                            </div>
                          </Tooltip>
                        )}
                      </>
                    ))}
                  </Stack>
                </Grid>
              )}
              {projectSettings?.canEditSlots && !isAdding && isEditing && (
                <>
                  <Grid>
                    <Box sx={{ mx: 'auto', p: 3 }}>
                      Чтобы другие пользователи могли отправлять Вам запросы на встречи, добавьте тайм-слоты в поле
                      ниже. Если у вас запланированы другие мероприятия – система подскажет Вам, что данное время у вас
                      уже занято.
                    </Box>
                  </Grid>
                  {showTableNumber && (
                    <Grid item xs={12} md={addSlotsCustom ? 6 : 3}>
                      <FormControl
                        margin="normal"
                        error={errors.tableNumber ? true : false}
                        disabled={isLocked}
                        style={{ width: '100%' }}
                      >
                        <InputLabel
                          shrink={true}
                          variant="standard"
                          sx={{
                            '& .MuiInputLabel-asterisk': {
                              color: 'red',
                            },
                            fontSize: 17,
                          }}
                        >
                          {TEXT[lang].TABLE}
                        </InputLabel>

                        <Select
                          variant="standard"
                          margin="dense"
                          {...register('tableNumber')}
                          value={timeSlot?.tableNumber}
                          style={inputStyle}
                        >
                          {getTableOptions()
                            // .filter((option) => {
                            //   return (
                            //     slots.find((s) => moment.unix(s.startTime / 1000).isSame(moment(timeSlot.date), 'days'))
                            //       ?.tableNumber !== option
                            //   );
                            // })
                            .map((option, i) => (
                              <MenuItem
                                key={i}
                                value={option}
                                onClick={() => setTimeSlot({ ...timeSlot, tableNumber: option })}
                              >
                                {option}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.tableNumber && (
                          <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                            {errors.tableNumber.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} md={showTableNumber ? (addSlotsCustom ? 6 : 4) : 6}>
                    <TextField
                      type="date"
                      margin="normal"
                      label={TEXT[lang].DATE}
                      sx={{ width: '100%' }}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          '& .MuiInputLabel-asterisk': {
                            color: 'red',
                          },
                        },
                        style: labelStyle,
                      }}
                      InputProps={{
                        style: inputStyle,
                        sx: {
                          '& .Mui-disabled': {
                            fill: '#000 !important',
                            '-webkit-text-fill-color': '#000 !important',
                          },
                        },
                      }}
                      variant="standard"
                      value={timeSlot?.date}
                      onChange={(e) => setTimeSlot((old) => ({ ...old, date: e.target.value }))}
                      error={errors.date ? true : false}
                      helperText={errors.date?.message}
                      disabled={isLocked}
                    />
                  </Grid>

                  {addSlotsCustom ? (
                    <>
                      <Grid item xs={12} md={showTableNumber ? 4 : 6}>
                        <ReactDatePicker
                          selected={timeSlot.fromTime}
                          onChange={(date) => setTimeSlot({ ...timeSlot, fromTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="h:mm aa"
                          minTime={moment().set('h', 10).set('minute', 0).set('second', 0).toDate()}
                          maxTime={moment().set('h', 21).set('minute', 0).set('second', 0).toDate()}
                          customInput={
                            <TextField
                              margin="normal"
                              label={TEXT[lang].FROM}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={showTableNumber ? 4 : 6}>
                        <ReactDatePicker
                          selected={timeSlot.toTime}
                          onChange={(date) => setTimeSlot({ ...timeSlot, toTime: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          dateFormat="h:mm aa"
                          minTime={
                            timeSlot.fromTime
                              ? moment(timeSlot.fromTime).add(1, 'hour').toDate()
                              : moment().set('h', 10).set('minute', 0).set('second', 0).toDate()
                          }
                          maxTime={moment().set('h', 21).set('minute', 0).set('second', 0).toDate()}
                          customInput={
                            <TextField
                              margin="normal"
                              label={TEXT[lang].TO}
                              variant="standard"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              error={
                                !!timeSlot.fromTime &&
                                !!timeSlot.toTime &&
                                moment(timeSlot.fromTime).isAfter(moment(timeSlot.toTime))
                              }
                              helperText={
                                !!timeSlot.fromTime &&
                                !!timeSlot.toTime &&
                                moment(timeSlot.fromTime).isAfter(moment(timeSlot.toTime))
                                  ? 'Неверное время'
                                  : ''
                              }
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={showTableNumber ? 4 : 6}>
                        <FormControl margin="normal" fullWidth error={errors.slots ? true : false} disabled={isLocked}>
                          <InputLabel id="select-label__slot-duration" shrink={true} variant="standard">
                            {TEXT[lang].DURATION}
                          </InputLabel>

                          <Select
                            labelId="select-label__slot-duration"
                            label={TEXT[lang].DURATION}
                            value={timeSlot?.duration}
                            variant="standard"
                          >
                            {[20, 30, 40, 50, 60].map((option, i) => (
                              <MenuItem
                                key={i}
                                value={option}
                                onClick={() => setTimeSlot({ ...timeSlot, duration: option })}
                              >
                                {option} {TEXT[lang].MINUTES}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} md={showTableNumber ? 5 : 6}>
                      <FormControl
                        margin="normal"
                        error={errors.slots ? true : false}
                        disabled={isLocked}
                        style={{ width: '100%' }}
                      >
                        <InputLabel
                          shrink={true}
                          variant="standard"
                          sx={{
                            '& .MuiInputLabel-asterisk': {
                              color: 'red',
                            },
                            fontSize: 17,
                          }}
                        >
                          {TEXT[lang].CONVENIENT_SLOT}
                        </InputLabel>

                        <Select
                          variant="standard"
                          margin="dense"
                          {...register('slots')}
                          value={timeSlot?.slot}
                          style={inputStyle}
                        >
                          {getTimeSlotOptions()
                            .filter((option) => {
                              return !slots.find((s) => {
                                return (
                                  `${moment.unix(s.startTime / 1000).format('HH:mm')} - ${moment
                                    .unix(s.endTime / 1000)
                                    .format('HH:mm')}` === option &&
                                  moment.unix(s.startTime / 1000).isSame(moment(timeSlot.date), 'days')
                                );
                              });
                            })
                            .map((option, i) => (
                              <MenuItem
                                key={i}
                                value={option}
                                onClick={() => setTimeSlot({ ...timeSlot, slot: option })}
                              >
                                {option}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.slots && (
                          <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={true}>
                            {errors.slots.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ color: '' }}
                      // startIcon={<Add />}
                      disabled={!ableAddSlot}
                      sx={{ whiteSpace: 'nowrap', width: '100%' }}
                      onClick={handleAddSlot}
                    >
                      {TEXT[lang].ADD_SLOT}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isLocked}
                      onClick={() => {
                        if (addSlotsCustom) {
                          setTimeSlot({ ...timeSlot, fromTime: '', toTime: '', duration: 0 });
                        }
                        setAddSlotsCustom(!addSlotsCustom);
                      }}
                      sx={{ whiteSpace: 'nowrap', width: '100%' }}
                    >
                      {TEXT[lang].CUSTOMIZE}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Stack>
          {isEditing && (
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12} lg={6} className={classes.button} style={{ justifyContent: 'flex-start' }}>
                <Button
                  className={classes.secondaryButton}
                  variant="contained"
                  size="large"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  {TEXT[lang].CANCEL}
                </Button>
              </Grid>
              <Grid item xs={12} lg={6} className={classes.button} style={{ justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  className={classes.submitButton}
                  variant="contained"
                  size="large"
                  disabled={isSubmitting || isLocked}
                >
                  {TEXT[lang].SAVE_CHANGES}
                </Button>
              </Grid>
            </Grid>
          )}
        </Stack>
      </form>
    </Grid>
  );
}

export default Members;
