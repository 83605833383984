import { useQuery } from 'react-query';
import { projectsAPIs } from 'src/services';
import { setProjectSettings } from 'src/store/actions/app';
import { PROJECT_SETTINGS } from 'src/utils/constant';

function useProjectSettings({ projectUuid }) {
  const fetchProjectBDK = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await projectsAPIs.getBDKSetting(projectUuid);
    const bdkSettings = res.message;
    setProjectSettings({
      showTableNumber: bdkSettings?.typeBDK === PROJECT_SETTINGS.TYPE_BDK.WITH_TABLE,
      allowIsBusySlot: bdkSettings?.allowIsBusySlot,
      typeProcessBDK: bdkSettings?.typeProcessBDK,
      payedMeetingCount: bdkSettings?.payedMeetingCount,
      canEditSlots: bdkSettings?.canEditSlots,
    });
    return bdkSettings;
  };

  const fetchAvatarSize = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await projectsAPIs.getAvatarSizeSetting(projectUuid);
    const avatarSize = res.message;
    setProjectSettings({ avatarSize: avatarSize });
    return avatarSize;
  };

  const { isLoading: isLoadingBDK, data: bdkSettings } = useQuery(['fetchProjectBDK', projectUuid], fetchProjectBDK, {
    enabled: !!projectUuid,
  });

  useQuery(['fetchAvatarSize', projectUuid], fetchAvatarSize, {
    enabled: !!projectUuid,
  });

  return { isLoadingBDK, typeBDK: bdkSettings?.typeBDK, allowIsBusySlot: bdkSettings?.allowIsBusySlot };
}

export default useProjectSettings;
