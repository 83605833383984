const PREFIX = 'APP__';

export const SET_INDUSTRIES = `${PREFIX}SET_INDUSTRIES`;
export const SET_COUNTRIES = `${PREFIX}SET_COUNTRIES`;
export const SET_ROLES = `${PREFIX}SET_ROLES`;
export const SET_PROJECTS = `${PREFIX}SET_PROJECTS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const SET_ORDER = `${PREFIX}SET_ORDER`;
export const RESET_STATE = `${PREFIX}RESET_STATE`;
export const SET_MEMBERS = `${PREFIX}SET_MEMBERS`;
export const SET_PAYING_ORDERS = `${PREFIX}SET_PAYING_ORDERS`;
export const SET_LANG = `${PREFIX}SET_LANG`;
export const SET_PROJECT_SETTINGS = `${PREFIX}SET_PROJECT_SETTINGS`;
export const SET_NOTIFICATIONS = `${PREFIX}SET_NOTIFICATIONS`;
export const SET_PAGE_STATE = `${PREFIX}SET_PAGE_STATE`;
export const SET_CURRENT_PROJECT = `${PREFIX}SET_CURRENT_PROJECT`;
