import {
  Avatar,
  Button,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { uniqueId } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LOCALIZATION, MEETING_STATUS, MESSAGES, TEXT } from 'src/utils/constant';
import { routes } from 'src/utils/routes';
import JoinProjectButton from '../join-project-button';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { MEETING_STATUS_COLOR } from 'src/utils/color';
import { DEFAULT_TIMEZONE } from 'src/utils/time';
import { Email, Person, Phone } from '@mui/icons-material';

function MeetingsTable({
  rootSx,
  tableSx,
  rows,
  total,
  hasDividerHeader,
  focusMeetingUuid,
  isSelf,
  onDeclineMeeting,
  onApproveMeeting,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang);
  const user = useSelector((state) => state.auth.user);
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});
  const projectSettings = useSelector((state) => state.app.projectSettings);
  const typeProcessBDK = projectSettings?.typeProcessBDK ?? 'bdk';
  const { projectUuid } = useParams();

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].NAME_COMPANY,
      width: '45%',
      onclick: () => {
        alert('ads');
      },
    },
    {
      id: 'member',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].MEMBERS,
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].DATE_AND_TIME,
    },
    ...(showTableNumber
      ? [
          {
            id: 'table',
            numeric: false,
            disablePadding: false,
            label: TEXT[lang].TABLE,
          },
        ]
      : []),
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: TEXT[lang].STATUS,
    },
    ...(isSelf
      ? [
          {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: '',
          },
        ]
      : []),
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log({ newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    console.log({ event });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (focusMeetingUuid) {
      const rowElement = document.getElementById(focusMeetingUuid);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [focusMeetingUuid]);

  return (
    <TableContainer id={uniqueId('table__')} component={Paper} sx={rootSx}>
      <Table size="medium" sx={tableSx}>
        <TableHead>
          <TableRow sx={{ background: '#F9FAFC' }}>
            {headCells.map((headCell, indexHead) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                width={headCell.width}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                    {headCell.label}
                  </Typography>
                  {hasDividerHeader && indexHead < headCells.length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                    />
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {total === 0 ? (
            <TableRow>
              <TableCell colSpan={headCells.length}>
                <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
                  {TEXT[lang].NO_DATA}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const fullName = (isSelf ? row?.fullNameOwner : row?.fullNameMember) || '';
                const email = (isSelf ? row?.emailOwner : row?.emailMember) || '';
                const telephone = (isSelf ? row?.telephoneOwner : row?.telephoneMember) || '';
                const role = (isSelf ? row?.roleOwner : row?.roleMember) || '';
                const companyName = (isSelf ? row?.companyOwner : row?.companyMember) || '';
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    role="checkbox"
                    id={row.uuid}
                    sx={{ background: focusMeetingUuid === row.uuid ? '#e8e8e8' : 'inherit' }}
                    // onClick={() => navigate(routes.projectDetail.path.replace(':projectUuid', row.uuid))}
                  >
                    <TableCell component="th" scope="row" padding="none" sx={{ minWidth: '60%' }}>
                      <Stack direction="row" alignItems="center" flexWrap="nowrap" gap="8px" paddingY="10px">
                        <Avatar
                          src={row?.avatar ? URL.createObjectURL(row.avatar) : ''}
                          sx={{ width: '50px', height: '50px' }}
                        >
                          {(fullName.split(' ')?.[0]?.[0] || '') + (fullName.split(' ')?.[1]?.[0] || '')}
                        </Avatar>
                        <Stack>
                          <Stack direction="row" gap="6px" alignItems="center">
                            <Typography
                              onClick={() => {
                                navigate(
                                  routes.projectVizits.path.replace(':projectUuid', projectUuid) +
                                    '?tab=search&companyname=' +
                                    companyName
                                );
                              }}
                              variant={'h6'}
                              sx={{ fontWeight: 'bold' }}
                            >
                              {companyName}
                            </Typography>
                          </Stack>

                          {role && (
                            <Typography variant={'h7'} sx={{ color: '#8A8A8E' }}>
                              {role}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Stack direction="row" gap="4px" alignItems="center">
                          <Person sx={{ color: '#8A8A8E', fontSize: '16px' }} />
                          <Typography color="#8A8A8E">{fullName}</Typography>
                        </Stack>
                        <Stack direction="row" gap="4px" alignItems="center">
                          <Email sx={{ color: '#8A8A8E', fontSize: '16px' }} />
                          <Typography color="#8A8A8E">{email}</Typography>
                        </Stack>
                        <Stack direction="row" gap="4px" alignItems="center">
                          <Phone sx={{ color: '#8A8A8E', fontSize: '16px' }} />
                          <Typography color="#8A8A8E">{telephone}</Typography>
                        </Stack>
                      </Stack>
                    </TableCell>

                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <Typography variant="body1">
                        {`${moment.tz(row?.startTime, DEFAULT_TIMEZONE).format('DD/MM HH:mm')} - ${moment
                          .tz(row?.endTime, DEFAULT_TIMEZONE)
                          .format('HH:mm')}`}
                      </Typography>
                    </TableCell>
                    {showTableNumber && (
                      <TableCell>
                        <Typography
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                          }}
                          variant="body1"
                        >
                          {row?.tableNumber}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      {row?.status && (
                        <div
                          style={{
                            width: 'fit-content',
                            padding: '4px 16px',
                            borderRadius: '6px',
                            fontSize: '14px',
                            letterSpacing: '0.5px',
                            color: 'white',
                            background: MEETING_STATUS_COLOR[row?.status],
                          }}
                        >
                          {MEETING_STATUS[row?.status][lang]}
                        </div>
                      )}
                    </TableCell>
                    {isSelf && (
                      <TableCell>
                        <Stack
                          direction="row"
                          gap="8px"
                          alignItems="center"
                          justifyContent={{ xs: 'center', md: 'flex-start' }}
                        >
                          {row.status !== MEETING_STATUS.declined[LOCALIZATION.en_US] &&
                            typeProcessBDK === 'networking' && (
                              <Button
                                variant="outlined"
                                sx={{
                                  height: '40px',
                                  width: '40px',
                                  minWidth: '40px',
                                  borderRadius: '4px',
                                  padding: '0',
                                }}
                                onClick={(e) => onDeclineMeeting(e, row)}
                              >
                                <CloseIcon />
                              </Button>
                            )}
                          {row.status !== MEETING_STATUS.approved[LOCALIZATION.en_US] &&
                            typeProcessBDK === 'networking' && (
                              <Button
                                variant="contained"
                                sx={{
                                  height: '40px',
                                  width: '40px',
                                  minWidth: '40px',
                                  borderRadius: '4px',
                                  padding: '0',
                                }}
                                onClick={() => onApproveMeeting(row)}
                              >
                                <CheckIcon />
                              </Button>
                            )}
                        </Stack>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
      {total > 10 && (
        <TablePagination
          labelRowsPerPage={`${MESSAGES[lang].ROWS_PER_PAGE}:`}
          labelDisplayedRows={({ count, from, to }) => {
            return `${from}-${to} ${TEXT[lang].OF} ${count}`.toLowerCase();
          }}
          page={page}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}

export default MeetingsTable;
