import {
  AccessTimeFilled,
  CalendarTodaySharp,
  ChevronLeft,
  Close,
  HourglassBottom,
  LocationOn,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { projectsAPIs } from 'src/services';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';
import BookingModal from './BookingModal';
import { BOOKING_STATUS, BOOKING_STATUS_CODES } from 'src/utils/constant';

const BookingDetailPage = () => {
  const [isBooking, setIsBooking] = useState(false);

  const [isModalActive, setIsModalActive] = useState(false);

  const [searchParams] = useSearchParams();

  const { lang, localizeMessage, localizeText } = useLanguage();

  const itemId = searchParams.get('id');

  const tab = searchParams.get('tab');

  const date = searchParams.get('date');

  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const { state } = useLocation();

  const fetchItem = async ({ queryKey }) => {
    const [_, projectUuid, itemId] = queryKey;
    const res = await projectsAPIs.getBookingItems(projectUuid, { id: itemId });
    return res?.message?.[0] || {};
  };
  const {
    isLoading: isFetching,
    data: fetchedItem,
    refetch,
  } = useQuery(['fetchItem', projectUuid, itemId], fetchItem, {
    enabled: !!projectUuid && !state,
  });

  const item = fetchedItem || state?.item;

  const status = item?.apply_status?.code ?? 0;

  const statusColor = useMemo(() => {
    switch (status) {
      case BOOKING_STATUS_CODES.CONFIRMED:
        return 'success';
      case BOOKING_STATUS_CODES.PENDING:
        return 'warning';
      case BOOKING_STATUS_CODES.REJECTED:
        return 'error';
      case BOOKING_STATUS_CODES.CANCELLED:
      default:
        return 'primary';
    }
  }, [status]);

  const handleCancel = async () => {
    try {
      await projectsAPIs.editBookingApply(projectUuid, item.apply_user.uuid, {
        status_code: BOOKING_STATUS_CODES.CANCELLED,
      });
      createNotification(localizeText.SUCCESS);
    } catch (e) {
      createErrorNotification(e?.message?.error || localizeMessage.ERROR);
    } finally {
      refetch();
    }
  };

  useEffect(() => {
    const replaceHistory = () => window.history.replaceState({}, '');
    window.addEventListener('beforeunload', replaceHistory);
    return () => {
      window.removeEventListener('beforeunload', replaceHistory);
    };
  }, []);

  return (
    <Stack sx={{ mt: { sm: 0, md: '-2rem' } }} gap={1} alignItems="center">
      <Stack marginBottom={'1rem'} gap={1} direction="row" alignSelf="flex-start" alignItems="center">
        <IconButton
          onClick={() =>
            navigate({
              pathname: routes.projectBooking.path.replace(':projectUuid', projectUuid),
              search: createSearchParams({ tab, date }).toString(),
            })
          }
        >
          <ChevronLeft />
        </IconButton>
        <Typography variant="h4">{localizeText.BOOKING_CARD_HEADER}</Typography>
      </Stack>
      {isFetching ? (
        <Loader />
      ) : (
        <Card sx={{ maxWidth: '1000px' }}>
          <CardContent>
            <Stack minHeight="200px" justifyContent="space-between">
              <Stack gap={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography display="flex" alignItems="center" variant="body2" color="textSecondary">
                    <AccessTimeFilled color="primary" sx={{ mr: '5px' }} />
                    {moment(item.apply_time.start_time).format('HH:mm')} -{' '}
                    {moment(item.apply_time.end_time).format('HH:mm')}
                    <Chip
                      size="small"
                      color="primary"
                      label={`GMT+${moment().utcOffset() / 60}`}
                      sx={{ fontSize: '10px', minHeight: '15px', ml: '5px' }}
                    />
                  </Typography>
                  <Typography display="flex" alignItems="center" variant="body2">
                    <CalendarTodaySharp color="primary" sx={{ mr: '5px' }} />
                    {moment(item.apply_date.date).format('DD MMMM YYYY')}
                  </Typography>
                </Stack>
                <Typography variant="h6" fontWeight="bold" component="div">
                  {item.apply_entity.localization[lang].name} ({item.apply_location.localization[lang].name})
                </Typography>
              </Stack>
              <Stack gap={2} mt={5}>
                <Stack direction="row" justifyContent="space-between">
                  {!!item.apply_entity.localization[lang].duration && (
                    <Typography display="flex" alignItems="center" fontSize="small" variant="body2">
                      <HourglassBottom color="primary" sx={{ mr: '5px' }} /> {localizeText.DURATION}:
                      {' ' + item.apply_entity.localization[lang].duration}
                    </Typography>
                  )}
                  {status !== BOOKING_STATUS_CODES.NONE && (
                    <Chip
                      label={BOOKING_STATUS[status][lang]}
                      size="small"
                      color={statusColor}
                      sx={{ fontSize: '10px', minHeight: '15px', ml: '5px' }}
                    />
                  )}
                </Stack>
                {!!item.apply_location.localization[lang].address && (
                  <Typography display="flex" alignItems="center" fontSize="small" variant="body2">
                    <LocationOn color="primary" sx={{ mr: '5px' }} fontSize="12px" />
                    {item.apply_location.localization[lang].address}
                  </Typography>
                )}
              </Stack>
              <Stack
                my={5}
                maxHeight={{ xs: '600px', sm: '400px', md: '450px', lg: '500px', xl: '600px' }}
                sx={{ overflowY: 'scroll' }}
              >
                <div dangerouslySetInnerHTML={{ __html: item.apply_entity.localization[lang].description }}></div>
              </Stack>
            </Stack>
            {status === BOOKING_STATUS_CODES.PENDING ? (
              <Stack>
                <Button disabled={isBooking} onClick={handleCancel} variant="contained">
                  {localizeText.BOOKING_CANCEL}
                </Button>
              </Stack>
            ) : (
              status === BOOKING_STATUS_CODES.NONE && (
                <Stack>
                  <Button disabled={isBooking} onClick={() => setIsModalActive(true)} variant="contained">
                    {localizeText.BOOKING_SUBMIT}
                  </Button>
                </Stack>
              )
            )}
          </CardContent>
        </Card>
      )}
      <BookingModal open={isModalActive} close={() => setIsModalActive(false)} item={item} refetch={refetch} />
    </Stack>
  );
};

export default BookingDetailPage;
