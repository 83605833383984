import React from 'react';
import { useSelector } from 'react-redux';
import { MEETING_STATUS_COLOR } from 'src/utils/color';
import { MEETING_STATUS } from 'src/utils/constant';

function MeetingStatus({ status }) {
  const lang = useSelector((state) => state.app.lang);
  return (
    <div
      style={{
        width: '140px',
        padding: '4px 16px',
        borderRadius: '6px',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        color: 'white',
        textAlign: 'center',
        background: MEETING_STATUS_COLOR[status],
      }}
    >
      {MEETING_STATUS[status]?.[lang]}
    </div>
  );
}

export default MeetingStatus;
