export default function CrossIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.01392 8.01367L14.6136 14.6133"
        stroke="#9AA3E1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01392 14.6133L14.6136 8.01362"
        stroke="#9AA3E1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
