import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccessTimeFilled,
  CalendarTodaySharp,
  ChevronLeft,
  Close,
  HourglassBottom,
  LocationOn,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { projectsAPIs } from 'src/services';
import { addMemberValidationSchema } from 'src/utils/formValidator';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';

const BookingModal = ({ open, item, close, refetch }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isModalActive, setIsModalActive] = useState(false);

  const [searchParams] = useSearchParams();

  const { lang, localizeMessage, localizeText } = useLanguage();

  const itemId = searchParams.get('id');

  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const { state } = useLocation();

  const user = useSelector((state) => state.auth.user);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        additional_info: '',
      }),
      [item]
    ),
  });

  const handleBook = async (values) => {
    setIsSubmitting(true);
    try {
      await projectsAPIs.applyBooking(projectUuid, {
        relation_id: +itemId,
        additional_info: values.additional_info,
      });
      createNotification(localizeText.BOOKING_SUCCESS);
    } catch (e) {
      createErrorNotification(e?.message?.error || localizeMessage.ERROR);
    } finally {
      close();
      setIsSubmitting(false);
      refetch();
    }
  };
  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%' } }} maxWidth="sm" open={open} onClose={close}>
      <DialogTitle align="center">
        <Typography variant="span">{localizeText.BOOKING_MODAL_HEADER}</Typography>
        <IconButton sx={{ position: 'absolute', right: '0.25rem', top: '0.25rem' }} onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      {!!item && (
        <DialogContent>
          <Stack gap={5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography display="flex" alignItems="center" variant="body2" color="textSecondary">
                {moment(item.apply_time.start_time).format('HH:mm')} -{' '}
                {moment(item.apply_time.end_time).format('HH:mm')}
                <Chip
                  size="small"
                  color="primary"
                  label={`GMT+${moment().utcOffset() / 60}`}
                  sx={{ fontSize: '10px', minHeight: '15px', ml: '5px' }}
                />
              </Typography>
              <Typography display="flex" alignItems="center" variant="body2">
                <CalendarTodaySharp color="primary" sx={{ mr: '5px' }} />
                {moment(item.apply_date.date).format('DD MMMM YYYY')}
              </Typography>
            </Stack>
            <Typography variant="h6" fontWeight="bold" component="div">
              {item.apply_entity.localization[lang].name} ({item.apply_location.localization[lang].name})
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit(handleBook)}>
            <Stack mt={5} gap={5}>
              <Stack gap={5} px={20}>
                <TextField
                  sx={{ backgroundColor: 'secondary.main' }}
                  disabled
                  size="small"
                  value={(user.lastName || '') + ' ' + (user.firstName || '') + ' ' + (user.middleName || '')}
                />
                {!!(user.email || user.phone) && (
                  <TextField
                    sx={{ backgroundColor: 'secondary.main' }}
                    disabled
                    size="small"
                    value={user.email || user.phone}
                  />
                )}
                <TextField
                  required
                  {...register('additional_info')}
                  placeholder={localizeText.BOOKING_ADD_INFO}
                  size="small"
                  error={!!errors.additional_info}
                  helperText={errors.additional_info?.message}
                />
                <Typography
                  color={'error'}
                  lineHeight={'1.3rem'}
                  textAlign="center"
                  dangerouslySetInnerHTML={{ __html: localizeMessage.BOOKING_ADD_INFO_SPAN }}
                  variant="body1"
                  component="div"
                />
              </Stack>
              <Button disabled={isSubmitting} type="submit" sx={{ width: '100%' }} variant="contained">
                {localizeText.BOOKING_COMPLETE}
              </Button>
            </Stack>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default BookingModal;
