import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button, Dialog, DialogContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userAPIs } from '../../services';
import { EVENTS, LOCALIZATION, MESSAGES, TEXT, MEETING_VALIDATION_TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import classes from './confirm-appointment-modal.module.sass';
import { DEFAULT_TIMEZONE, displayTimeByFormat } from '../../utils/time';

export const ConfirmAppointmentModal = ({ projectUuid, chat }) => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const user = useSelector((state) => state.auth.user);
  const { showTableNumber } = useSelector((state) => state.app.projectSettings || {});
  const [state, setState] = useState({
    show: false,
    data: null,
    onOk: () => {},
  });

  const handleCancel = () => setState({ show: false });
  const handleShow = async (data) => {
    setState({ ...state, show: true, data });
  };

  const checkUserVizit = async () => {
    try {
      await userAPIs.getUserVizitsProject(projectUuid);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleCreateAppointment = async () => {
    try {
      if (!(await checkUserVizit(projectUuid))) {
        createErrorNotification(MESSAGES[lang].DO_NOT_HAVE_USER_VIZIT);
        return;
      }
      const vizit = state.data;
      const res = await userAPIs.confirmMeeting(projectUuid, {
        chatUuid: vizit.chatUuid,
        vizitUuid: vizit.vizitUuid,
        memberUuid: vizit.userUuid,
        tableNumber: vizit.slot?.tableNumber || 0,
        startTime: vizit.slot?.startTime,
        endTime: vizit.slot?.endTime,
      });
      createNotification(
        MESSAGES[lang].CONFIRMED_MEETING_NETWORKING(state.data?.slot?.startTime, state.data?.slot?.endTime)
      );
      if (state.data?.callback) state.data?.callback();
    } catch (e) {
      console.log(e);
      if (e.code === 409) {
        createErrorNotification(MESSAGES[lang].SLOT_BUSY_ERROR);
      } else if (e.code === 501) {
        createErrorNotification(MESSAGES[lang].NO_QUOTA);
      } else {
        createErrorNotification(MESSAGES[lang].ERROR);
      }
    } finally {
      handleCancel();
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_CONFIRM_APPOINTMENT_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_CONFIRM_APPOINTMENT_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xs: 'auto', md: '50vw' },
            // minHeight: '60%',
            borderRadius: '30px',
            // border: '4px solid #c1c1ff',
          },
        }}
        // maxWidth="md"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              zIndex: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <div className={classes.body}>
            <Stack gap="32px">
              <Typography className={classes.title}>{TEXT[lang].CONFIRMED_MEETING}</Typography>
              <Stack direction="row" gap="18px" flexWrap="wrap" className={classes.mainContent}>
                <Avatar
                  src={state.data?.avatar ? URL.createObjectURL(state.data?.avatar) : ''}
                  className={classes.avatar}
                >
                  {(state.data?.fullName?.split(' ')?.[0]?.[0] || '') +
                    (state.data?.fullName?.split(' ')?.[1]?.[0] || '')}
                </Avatar>
                <Stack>
                  <Typography
                    variant={'h6'}
                    className={classes.max3Lines}
                    sx={{ color: '#0D0D0D', fontSize: '17px', fontWeight: 'bold' }}
                  >
                    {state.data?.fullName}
                  </Typography>
                  <Typography variant={'h7'} className={classes.max3Lines} sx={{ color: '#8A8A8E' }}>
                    {state.data?.role}
                  </Typography>
                  <Typography variant={'h6'} sx={{ color: '#0D0D0D', fontSize: '15px', marginTop: '16px' }}>
                    {MESSAGES[lang].TIME_AND_PLACE_OF_MEETING}:
                  </Typography>
                  <Stack direction="row" gap="12px" alignItems="center" marginTop="8px" flexWrap="wrap">
                    <div className={classes.slot}>
                      {`${displayTimeByFormat(state.data?.slot?.startTime, 'HH:mm')} - ${displayTimeByFormat(
                        state.data?.slot?.endTime,
                        'HH:mm'
                      )}  ${displayTimeByFormat(state.data?.slot?.startTime, 'D.MM.YYYY dddd')}`}
                    </div>
                    {showTableNumber && (
                      <div className={classes.tableNumber}>
                        {TEXT[lang].TABLE_NO + ' ' + state.data?.slot?.tableNumber}
                      </div>
                    )}
                  </Stack>
                </Stack>
              </Stack>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    className={classes.primaryButton}
                    endIcon={<CheckIcon />}
                    disabled={!!state.data?.error}
                    onClick={handleCreateAppointment}
                    sx={{
                      '&.Mui-disabled': {
                        color: 'black',
                        backgroundColor: '#F5F5F5',
                      },
                    }}
                  >
                    {TEXT[lang].I_CONFIRM}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button color="secondary" className={classes.secondaryButton} onClick={handleCancel}>
                    {TEXT[lang].CANCEL}
                  </Button>
                </Grid>
              </Grid>

              {state.data?.error && (
                <div style={{ color: 'red', textAlign: 'center' }}>
                  {MEETING_VALIDATION_TEXT[state.data?.error][lang]}
                </div>
              )}
              {state.data?.message && (
                <div style={{ color: '#3bc740', textAlign: 'center' }}>
                  {MEETING_VALIDATION_TEXT[state.data?.message][lang]}
                </div>
              )}
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
