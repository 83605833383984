import {
  AccessTime,
  AccessTimeFilled,
  CalendarToday,
  CalendarTodaySharp,
  HourglassBottom,
  LocationOn,
} from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, CardHeader, Chip, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { BOOKING_STATUS, BOOKING_STATUS_CODES } from 'src/utils/constant';
import { routes } from 'src/utils/routes';

const BookingItemCard = ({ item, activeTab, selectedDate }) => {
  const { lang, localizeText } = useLanguage();
  const navigate = useNavigate();
  const { projectUuid } = useParams();
  const handleClick = () => {
    navigate(
      {
        pathname: routes.projectBookingDetail.path.replace(':projectUuid', projectUuid),
        search: createSearchParams({ id: item.apply_relation.id, tab: activeTab, date: selectedDate }).toString(),
      },
      {
        state: {
          item,
        },
      }
    );
  };
  const status = item.apply_status?.code ?? 0;

  const statusColor = useMemo(() => {
    switch (status) {
      case BOOKING_STATUS_CODES.CONFIRMED:
        return 'success';
      case BOOKING_STATUS_CODES.PENDING:
        return 'warning';
      case BOOKING_STATUS_CODES.REJECTED:
        return 'error';
      case BOOKING_STATUS_CODES.CANCELLED:
      default:
        return 'primary';
    }
  }, [status]);
  return (
    <Card>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Stack height="200px" justifyContent="space-between">
            <Stack gap={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography display="flex" alignItems="center" variant="body2" color="textSecondary">
                  <AccessTimeFilled color="primary" sx={{ mr: '5px' }} />
                  {moment(item.apply_time.start_time).format('HH:mm')} -{' '}
                  {moment(item.apply_time.end_time).format('HH:mm')}
                  <Chip
                    size="small"
                    color="primary"
                    label={`GMT+${moment().utcOffset() / 60}`}
                    sx={{ fontSize: '10px', minHeight: '15px', ml: '5px' }}
                  />
                </Typography>
                <Typography display="flex" alignItems="center" variant="body2">
                  <CalendarTodaySharp color="primary" sx={{ mr: '5px' }} />{' '}
                  {moment(item.apply_date.date).format('DD MMMM YYYY')}
                </Typography>
              </Stack>
              <Typography
                variant="h6"
                fontWeight="bold"
                component="div"
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 3,
                }}
              >
                {item.apply_entity.localization[lang].name} ({item.apply_location.localization[lang].name})
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Stack direction="row" justifyContent="space-between">
                {!!item.apply_entity.localization[lang].duration && (
                  <Typography display="flex" alignItems="center" fontSize="small" variant="body2">
                    <HourglassBottom color="primary" sx={{ mr: '5px' }} /> {localizeText.DURATION}:
                    {' ' + item.apply_entity.localization[lang].duration}
                  </Typography>
                )}
                {status !== BOOKING_STATUS_CODES.NONE && (
                  <Chip
                    label={BOOKING_STATUS[status][lang]}
                    size="small"
                    color={statusColor}
                    sx={{ fontSize: '10px', minHeight: '15px', ml: '5px' }}
                  />
                )}
              </Stack>
              {!!item.apply_location.localization[lang].address && (
                <Typography display="flex" alignItems="center" fontSize="small" variant="body2">
                  <LocationOn color="primary" sx={{ mr: '5px' }} fontSize="12px" />
                  {item.apply_location.localization[lang].address}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BookingItemCard;
