import { Card, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getFullName } from '../../utils/common';
import { AvatarUser } from '../avatar-user';
import ButtonMyTicket from '../button-my-ticket';
import UserRole from '../user-role';
import UserStatus from '../user-status';
import classes from './main-profile.module.sass';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useResponsive } from 'src/hooks';
import { Help, Margin } from '@mui/icons-material';

export function MainUserProfile({
  user = {},
  role,
  projectUuid,
  hideAvatar,
  hideInfo,
  sx,
  hideUploadAvatar,
  ...restProps
}) {
  const currentUser = useSelector((state) => state.auth.user);
  const isDesktop = useResponsive();

  const isCurrentUser = currentUser?.uuid === user?.uuid;
  const isAccredited = user?.projectSettings?.[projectUuid]?.isAccredited;
  const readyToAccreditate = user?.projectSettings?.[projectUuid]?.readyToAccreditate;

  return (
    <Card
      sx={{
        color: (theme) => (isCurrentUser ? theme.palette.primary.contrastText : theme.palette.common.black),
        ...sx,
        background: (theme) => (isCurrentUser ? theme.palette.primary.main : theme.palette.white),
      }}
      className={classes.root}
      {...restProps}
    >
      <Stack gap="10px" direction="row" alignItems="center" justifyContent="center">
        <Grid container alignItems="center" justifyContent="center">
          <Grid container xs={12} sm={4}>
            {hideAvatar ? <></> : <AvatarUser user={user} hideUploadAvatar={hideUploadAvatar} />}
            <Grid display="flex" alignItems="start" justifyContent="center" flexDirection="column" marginLeft="20px">
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#3A3541DE' }}>
                {getFullName(user)}
              </Typography>
              <Typography sx={{ fontSize: '12px', color: '#3A354199' }}>{user.email}</Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={isAccredited ? 5 : 8} justifyContent="center" margin="10px 0">
            {hideInfo ? (
              <></>
            ) : (
              <Stack gap="8px" alignItems="start">
                <Stack direction="row" gap="10px" alignItems="center">
                  <Typography sx={{ fontSize: '14px', color: '#89868D' }}>
                    Роль участника:
                    <Help sx={{ color: '#3A35418A', fontSize: '14px' }} />
                  </Typography>
                  <UserRole role={role} sx={{ alignItems: 'center', display: 'flex' }} icon={<CheckCircleIcon />} />
                </Stack>
                <Stack direction="row" gap="10px" alignItems="center">
                  <Typography sx={{ fontSize: '14px', color: '#89868D' }}>
                    Статус анкеты:
                    <Help sx={{ color: '#3A35418A', fontSize: '14px' }} />
                  </Typography>
                  <UserStatus
                    isAccredited={isAccredited}
                    readyToAccreditate={readyToAccreditate}
                    sx={{ height: '30px', alignItems: 'center', display: 'flex' }}
                    icon={<CheckCircleIcon />}
                    project={projectUuid}
                  />
                </Stack>
              </Stack>
            )}
          </Grid>
          {isAccredited && (
            <Grid container xs={12} sm={3} justifyContent="center" margin="10px 0 10px 0">
              <ButtonMyTicket userUuid={user.uuid} projectUuid={projectUuid} sx={{ height: '40px' }} />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Card>
  );
}
