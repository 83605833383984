import { LOCALIZATION } from '../../utils/constant';
import * as constants from '../constants/app';

const initialState = {
  industries: [],
  countries: [],
  roles: [],
  projects: [],
  isLoadingProjects: false,
  order: [],
  members: [],
  payingOrders: [],
  lang: LOCALIZATION.ru_RU,
  projectSettings: {
    typeBDK: 'without_table',
    allowIsBusySlot: false,
    typeProcessBDK: 'bdk',
  },
  notifications: [],
  pageState: {
    vizits: { tab: '' },
  },
  currentProject: null,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    case constants.SET_INDUSTRIES:
      return {
        ...state,
        industries: action.industries,
      };
    case constants.SET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case constants.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case constants.SET_LOADING:
      return {
        ...state,
        [action.dataType]: action.loading,
      };
    case constants.SET_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case constants.SET_MEMBERS:
      return {
        ...state,
        members: action.members,
      };
    case constants.SET_PAYING_ORDERS:
      return {
        ...state,
        payingOrders: action.payingOrders,
      };
    case constants.SET_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    case constants.SET_PROJECT_SETTINGS:
      return {
        ...state,
        projectSettings: {
          ...state.projectSettings,
          ...action.payload,
        },
      };
    case constants.SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case constants.SET_PAGE_STATE:
      return { ...state, pageState: action.payload };
    case constants.SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.payload };
    case constants.RESET_STATE:
      return { ...initialState, lang: state.lang };
    default:
      return state;
  }
}
