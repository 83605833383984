import { Dialog, DialogContent, Link, Stack, Typography } from '@mui/material';
import { Button } from 'src/components';
import { useLanguage } from 'src/hooks';

function NotifyModal({ open, onClose }) {
  const { localizeMessage } = useLanguage();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack alignItems="center" gap="16px" padding="8px">
          <Typography fontWeight="bold" fontSize="18px">
            {localizeMessage.REGISTER_REACH_LIMIT_NOTIFY_1}
            <Link href={'https://ski.lk.expoforum.ru'}>https://ski.lk.expoforum.ru</Link>
            {localizeMessage.REGISTER_REACH_LIMIT_NOTIFY_2}
            <span style={{ textDecoration: 'underline', color: '#0256cd' }}>ski.lk@expoforum.ru</span>
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              window.location.assign('https://ski.lk.expoforum.ru');
            }}
          >
            {localizeMessage.GO_TO_HOME}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default NotifyModal;
